<template>
  <el-dialog v-model="returnDialog" title="退费申请" width="1100" draggable>
    <div>
      <el-form :rules="formRules" ref="formRules" :model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="银行名称" prop="accountBank" label-width="100px" >
              <el-input  v-model="form.accountBank" placeholder="如：**银行"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="网点地址" prop="placeAccount" label-width="100px" >
              <el-input  v-model="form.placeAccount" placeholder="如：****路支行"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="银行户主" prop="nameAccount" label-width="100px" >
              <el-input v-model="form.nameAccount" maxlength="11" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="银行卡号" prop="payAccount" label-width="100px">
              <el-input v-model="form.payAccount"  placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="退款金额" prop="money" label-width="100px">
              <el-input v-model="form.money" maxlength="11" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">          
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div style="display:flex;justify-content: space-between">
              <div style="width: 55%;">
                <div style="background-color: #F5F7FA;padding: 10px 14px;border-radius: 10px">
                  <div style="margin-bottom: 20px;font-weight: bold">缴费凭证</div>
                  <div style="background-color: white;padding: 10px;border-radius: 10px">
                    <el-upload
                        ref='upload'
                        class="upload"
                        accept="image/jpg,image/jpeg,image/png"
                        :http-request="uploadCardProduct"
                        :limit="9"
                        list-type="picture-card">
                      <el-icon><Plus /></el-icon>
                    </el-upload>
                  </div>

                </div>
                <div style="background-color: #F5F7FA;margin:10px 0 0 0px;padding: 10px 14px;border-radius: 10px">
                  <div style="margin-bottom: 20px;font-weight: bold">退款说明</div>
                  <el-input
                      v-model="form.remark"
                      :rows="4"
                      type="textarea"
                      placeholder="请输入"
                  />
                </div>
              </div>
              <div style="background-color: #F5F7FA;padding: 10px 14px;border-radius: 10px;width: 43%">
                <div style="margin-bottom: 20px;font-weight: bold">分成老师</div>
                <div v-for="(item,index) in form.divideList" :key="item.id" >
                  <div style="background-color: white;display: flex;padding: 10px;border-radius: 10px;margin-top: 10px">
                    <div style="display: flex;align-items: center">
                      <div style="width: 70px;">姓名：</div>
                        <el-autocomplete
                          v-model="item.name"
                          :fetch-suggestions="querySearchAsync"
                          placeholder="教练-老师名称"
                          :trigger-on-focus="false"
                          :clearable="true"
                          @change="changeIndex(index)"
                          @select="selectCocahName"
                        ></el-autocomplete>  
                      <!-- <el-input v-model="item.name" placeholder="请输入" /> -->
                    </div>
                    <div style="display: flex;align-items: center;margin-left: 10px">
                      <div style="width: 70px;">金额：</div>
                      <el-input v-model="item.money" placeholder="请输入" style="margin-left: 10px" />
                    </div>
                  </div>
                </div>
                <div v-if="form.divideList.length<4" style="display: flex;justify-content: center;margin-top: 10px">
                  <el-button type="primary" @click="addDivide" plain>新增</el-button>
                </div>
              </div>
            </div>
     
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">

          </el-col>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="returnDialog = false">取 消</el-button>
        <el-button type="primary" @click="returnMoney">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {uploadImage} from '@/api/helpManual'
import {returnMoney,auditorList} from '@/api/attract'
import { pageList} from  "@/api/coach";
export default {
  name: "ReturnMoney",
  data() {
    return {
      divideIndex:0,
      AllCaochList:[],
      returnDialog:false,
      form:{
        orderId:null,
        payAccount:"",
        nameAccount:"",
        placeAccount:"",
        accountBank:"",
        imageList:[],
        // localStorage.getItem('coachId')
        divideList:[
            {id:localStorage.getItem('coachId'),
              name:localStorage.getItem("ms_username"),
              money:null}],
        type:4,
        campusId:0,
        auditorList:[]
      },
      formRules:{
        name: [{required: true, message: '请输入！', trigger: 'change'},],
        phone: [
          {required: true,message: '请输入联系电话！',trigger: 'change'},
          {pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号码", trigger: "blur",}],
        payAccount:[
          {required: true,message: '请输入银行卡账号！',trigger: 'change'},
          {pattern: /^(\d{12,19})$/, message: "请输入正确银行卡号", trigger: "blur",}
        ]
      }
    }
  },
  methods:{
    async  querySearchAsync(queryString, cb) {
      let query = { 
        areaList: [],
        keyword: queryString,
        pageIndex: 1,
        pageSize: 10,
        state: "开启",
        type: "",
      }
    await  pageList(query).then(res=>{ 
        if(res.code==200){ 
          this.AllCaochList = res.data.records
        }
      })
      let apiResult = []
      this.AllCaochList.forEach(item=>{ 
        let obj = {}
        obj.value = item.coachName
        obj.id = item.coachId
        apiResult.push(obj)
      })
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        cb(apiResult)
      }, 600)
    },
    changeIndex(index){
      this.divideIndex = index
    },
    selectCocahName(item){
      let divideList = this.form.divideList
      let divideIndex = this.divideIndex 
      divideList[divideIndex].id = item.id
      divideList[divideIndex].name = item.value
    },
    openDialog(item) {
      this.form.orderId = item.id;
      this.returnDialog = true;
      auditorList(0,localStorage.getItem('coachId'),item.id,'crmRefund').then(res=>{ 
        if(res.code == 200){ 
          if(res.data.length == 0){ 
            this.$message.error('暂无审核人！无法提交退费申请，请联系技术部门！')
            this.returnDialog = false
            return
          }
          this.form.auditorList = res.data
        }else{ 
          this.$message.error(res.message)
        }
      })
    },
    addDivide(){
      let item = {name:"",id:null,money:null}
      this.form.divideList.push(item);
    },
    returnMoney(){
      this.$refs['formRules'].validate((valid) => {
        if (valid){
          returnMoney(this.form).then(val=>{
            Object.keys(this.form).forEach((key) => (this.form[key] = ''));
            if (val.code == 200) {
              this.$message.success("退费成功");
              this.returnDialog = false;
            } else {
              this.$message.error(val.message);
            }
          })
        }
      })
    },
    async uploadCardProduct(file) {
      const fileName = file.file.name;
      const lastIndex = fileName.lastIndexOf(".");
      if (lastIndex !== -1) {
        const extension = fileName.substring(lastIndex + 1);
        let a = ['png', 'jpg', 'jpeg','PNG', 'JPG', 'JPEG'];
        if (a.includes(extension)) {
          await this.uploadImg(file,fileName)
        }else {
          this.$message.warning('图片格式有误！');
        }
      }
    },
    async uploadImg(file) {
      let url = null
      const data = new FormData()
      data.append('multipartFile', file.file)
      data.append('flag', 'card')
      data.append('fileName', file.file.name)
      await uploadImage(data).then(res => {
        if (res.code == 200) {

          this.form.imageList.push(res.data);
        } else {
          this.$message.warning('图片上传失败');
        }
      })
      return url
    },
  }
}
</script>

<style scoped>
/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}

/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
</style>